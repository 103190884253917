<template>
    <span>
        <v-footer dark padless>
            <v-container fluid>
                <v-row justify="space-around">
                    <v-card width="100%">
                        <v-img src="@/assets/banner_rosa.jpg">
                            <v-card-text>
                                <v-container fluid>
                                    <v-row>
                                        <v-col cols="6" md="2">
                                            <v-img width="70%" src="@/assets/logo_blanco.png"></v-img>
                                        </v-col>
                                        <v-col cols="6" md="3">
                                            <v-container class="pa-0" fill-height>
                                                <v-row class="ma-0 pa-0">
                                                    <v-col cols="12" lg="8" class="font-weight-bold pa-0 colibrije_mestiza_medium">
                                                        CONTACTO
                                                    </v-col>
                                                </v-row>
                                                
                                                <v-row class="ma-0 pa-0">
                                                    <v-col cols="11" lg="5" class="caption ma-0 pa-0">
                                                        <v-icon small>mdi-phone</v-icon> &nbsp;+52 55 3593 8908
                                                    </v-col>
                                                </v-row>
                                                <v-row class="ma-0 pa-0">
                                                    <v-col cols="11" lg="5" class="caption ma-0 pa-0">
                                                        <v-icon small>mdi-email</v-icon> &nbsp;info@colibrije.com
                                                    </v-col>
                                                </v-row>
                                                <v-row class="ma-0 pa-0">
                                                    <v-col cols="11" lg="5" class="caption ma-0 pa-0">
                                                        <v-icon small>mdi-map-marker</v-icon> &nbsp;Ciudad de México, México
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-col>
                                        <v-col cols="12" md="7">
                                            <v-container class="pa-0">
                                                <v-row>
                                                    <v-col
                                                        cols="12"
                                                        :class="{ 'text-center': $vuetify.breakpoint.xs,
                                                                  'text-right': $vuetify.breakpoint.mdAndUp }"
                                                    >
                                                        <v-btn
                                                            text
                                                            class="text-none colibrije_mestiza_medium"
                                                            @click="onHistoria"
                                                            >HISTORIA
                                                        </v-btn>
                                                    
                                                        <v-btn
                                                            text
                                                            class="text-none colibrije_mestiza_medium"
                                                            @click="onDestilacion"
                                                            >DESTILACIÓN
                                                        </v-btn>
                                                    
                                                        <v-btn
                                                            text
                                                            class="text-none colibrije_mestiza_medium"
                                                            @click="onAvisoPrivacidad"
                                                            >AVISO DE PRIVACIDAD
                                                        </v-btn>
                                                    
                                                        <v-btn
                                                            text
                                                            class="text-none colibrije_mestiza_medium"
                                                            @click="onCondiciones"
                                                            >TÉRMINOS Y CONDICIONES
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <!-- <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer> -->
                                                    <v-col
                                                        cols="12"
                                                        :class="{ 'text-center': $vuetify.breakpoint.xs,
                                                                  'text-right': $vuetify.breakpoint.mdAndUp }"
                                                    >
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn text @click="onCart" v-on="on">
                                                                    <v-container>
                                                                        <v-layout row>
                                                                            <v-flex xs12>
                                                                                <div class="imgHolderMainMenu">
                                                                                    <img
                                                                                        position="left"
                                                                                        height="25"
                                                                                        src="@/assets/carrito_white.png"
                                                                                    />
                                                                                    <span class="catabocata-brown subtitle-1">{{ cartItems }}</span>
                                                                                </div>
                                                                            </v-flex>
                                                                        </v-layout>
                                                                    </v-container>
                                                                </v-btn>
                                                            </template>
                                                            <span>Carrito de compras</span>
                                                        </v-tooltip>
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn text @click="onInstagram" v-on="on">
                                                                    <img height="25" src="../assets/instagram_white.png" />
                                                                </v-btn>
                                                            </template>
                                                            <span>Síguenos en Instagram</span>
                                                        </v-tooltip>
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn text @click="onFacebook" v-on="on">
                                                                    <img height="25" src="../assets/facebook_white.png" />
                                                                </v-btn>
                                                            </template>
                                                            <span>Síguenos en Facebook</span>
                                                        </v-tooltip>
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn text @click="onWhatsApp" v-on="on">
                                                                    <img height="25" src="../assets/whatsapp_white.png" />
                                                                </v-btn>
                                                            </template>
                                                            <span>Contáctanos por Whatsapp</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-img>
                    </v-card>
                </v-row>  
            </v-container>
        </v-footer>
    </span>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
    name: 'AppFooter',
    data() {
        return {
            icons: [
                'mdi-instagram',
                'mdi-facebook',
                'mdi-whatsapp'
            ]
        };
    },
    methods: {
        onHistoria() {
            this.$router.push('/historia').catch(err => {});
        },
        onDestilacion() {
            //this.$router.push('/destiacion').catch(err => {});
        },
        onAvisoPrivacidad() {
            //this.$router.push('/avisoprivacidad').catch(err => {});
        },
        onCondiciones() {
            //this.$router.push('/condiciones').catch(err => {});
        },
        onInstagram() {},
        onFacebook() {},
        onWhatsApp() {},
        onCart() {},
    },
    computed: {
        ...mapGetters(['version']),
        
        cartItems: {
            get() {
                return this.$store.getters.cartItems;
            },

        }
    },
}

</script>

<style scoped>
/* ***************Modificaciones para que no se parta el texto en v-card-text y en v-card-title (al parecer es un bug de vuetify):************* */
.v-card__text,
.v-card__title {
    word-break: normal; /* maybe !important  */
}

.colibrije_mestiza_black {
    font-family: mestiza_black;
    font-size: 1.02em;
}

@font-face {
    font-family: mestiza_black;
    src: url('../css/Mestiza-Black.otf');
}

.colibrije_mestiza_medium {
    font-family: mestiza_medium;
    font-size: .91em;
}

@font-face {
    font-family: mestiza_medium;
    src: url('../css/Mestiza-Black.otf');
}
</style>