<template>
    <span>
        <!-- Menú lateral que aparece sólo cuando drawer = true, siendo ésto sólo posible en ventanas sm: -->
        <v-navigation-drawer app v-model="drawer" class="grey lighten-5" disable-resize-watcher>
            <v-list dense class="pt-0">
                <v-list-item></v-list-item>
            </v-list>
        </v-navigation-drawer>
        <!-- Termina menú lateral para dispositivos con ventanas sm -->

        <v-app-bar flat color="white" clipped-left>
            <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer"></v-app-bar-nav-icon>

            <v-toolbar-title class="hidden-sm-and-down">
                <router-link to="/">
                    <img height="55" :src="navMainLogo" alt="">
                </router-link>
            </v-toolbar-title>

            <v-toolbar-title class="hidden-md-and-up">
                <router-link to="/">
                    <img height="35" :src="navMainLogo" />
                </router-link>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-toolbar-items class="hidden-sm-and-down">
                
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn class="colibrije_pink" text @click="onCart" v-on="on">
                            <v-container>
                                <v-layout row>
                                    <v-flex xs12>
                                        <div class="imgHolderMainMenu">
                                            <img
                                                position="left"
                                                height="25"
                                                src="@/assets/carrito_blue.png"
                                            />
                                            <span class="catabocata-brown subtitle-1">{{ cartItems }}</span>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-btn>
                    </template>
                    <span>Carrito de compras</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn class="colibrije_pink" text @click="onInstagram" v-on="on">
                            <img height="25" src="../assets/instagram_blue.png" />
                        </v-btn>
                    </template>
                    <span>Síguenos en Instagram</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn class="colibrije_pink" text @click="onFacebook" v-on="on">
                            <img height="25" src="../assets/facebook_blue.png" />
                        </v-btn>
                    </template>
                    <span>Síguenos en Facebook</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn class="colibrije_pink" text @click="onWhatsApp" v-on="on">
                            <img height="32" src="../assets/whatsapp_blue.png" />
                        </v-btn>
                    </template>
                    <span>Contáctanos por Whatsapp</span>
                </v-tooltip>
            </v-toolbar-items>
        </v-app-bar>
    </span>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'AppNavigation',
    data() {
        return {
            drawer: false,
            overlay: false
        };
    },

    methods: {
        onHistoria() {},
        onDestilacion() {},
        onCatalogo() {},
        onExperiencias() {},
        onContacto() {},
        onRecetas() {},
        onModosDeBeber() {},
        onContacto() {},
        onInstagram() {},
        onFacebook() {},
        onWhatsApp() {},
        onCart() {},
    },

    computed: {
        ...mapGetters(['navAppTitle', 'navMainLogo']),
        cartItems: {
            get() {
                return this.$store.getters.cartItems;
            },

        }
    },

}
</script>


<style scoped>
.colibrije_mestiza_black {
    font-family: mestiza_black;
    font-size: 1.02em;
}

@font-face {
    font-family: mestiza_black;
    src: url('../css/Mestiza-Black.otf');
}

.colibrije_mestiza_medium {
    font-family: mestiza_medium;
    font-size: 1.02em;
}

@font-face {
    font-family: mestiza_medium;
    src: url('../css/Mestiza-Black.otf');
}

.colibrije_pink {
    color: #e61065
}

.imgHolderMainMenu {
    position: relative;
}
.imgHolderMainMenu span {
    position: absolute;
    right: 28px;
    top: -10px;
}

.imgHolderSideMenu {
    position: relative;
}
.imgHolderSideMenu span {
    position: absolute;
    right: 6px;
    top: -5px;
}

</style>