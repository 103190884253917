<template>
  <span>
    <v-parallax :height="carouselParallaxHeight" src='@/assets/garigoleado_cafe1.jpg'>
      <v-carousel
        height="auto"
        hide-delimiter-background
        show-arrows-on-hover
        cycle
      >
        <v-carousel-item
          v-for="(item, i) in items"
          :key="i"
        >
        <v-container class="fill-height" fluid>
          <v-row align="center" justify="space-around">
            <v-col class="text-center" cols="12" md="6">
              <div
                class="colibrije_mestiza_medium"
                :class="{'my_carousel_xs': $vuetify.breakpoint.xs,
                         'my_carousel_sm': $vuetify.breakpoint.sm,
                         'my_carousel_md': $vuetify.breakpoint.mdAndUp,
                         'colibrije_pink': item.textColor === 'pink',
                         'colibrije_blue': item.textColor === 'blue'}"
                >
                {{ item.text }}
              </div>
            </v-col>
            <v-col cols="12" md="3" align-self="center">
                <v-img
                  v-if="item.img !== ''"
                  :src="item.img"
                  :width="carouselImgWidth"
                  class="center_img"
                ></v-img>
            </v-col>
          </v-row>
        </v-container>
        </v-carousel-item>
      </v-carousel>
    </v-parallax>

    <!-- <v-parallax :height="formasBeberParallaxHeight" src='@/assets/colibrije_d1.png'> -->
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="text-center">
          <div
            class="colibrije_pink colibrije_mestiza_medium"
            :class="{ 'unicos_title_xs': $vuetify.breakpoint.xs,
                      'unicos_title_sm': $vuetify.breakpoint.sm,
                      'unicos_title_md': $vuetify.breakpoint.mdAndUp }"
          >
          <br>
          QUÉ NOS HACE ÚNICOS
          <br>
          <br>
        </div>
        </v-col>
      </v-row>
      <v-divider v-if="$vuetify.breakpoint.smAndDown" class="pink darken-1"></v-divider>
      <br v-if="$vuetify.breakpoint.smAndDown">
      <v-row>
        <v-col cols="12" md="4">
          <v-container>
            <v-row>
              <v-col cols="12" class="text-center"  align="center">
                <div
                  class="colibrije_pink colibrije_mestiza_medium"
                  :class="{ 'unicos_subtitle_xs': $vuetify.breakpoint.xs,
                            'unicos_subtitle_sm': $vuetify.breakpoint.sm,
                            'unicos_subtitle_md': $vuetify.breakpoint.mdAndUp }"
                  >
                  Nuestro Agave
                </div>
              </v-col>
            </v-row>
            <br>
            <br v-if="$vuetify.breakpoint.mdAndUp">
            <v-row>
              <v-col cols="12" class="text-center">
                <div 
                  class="colibrije_pink colibrije_mestiza_regular"
                  :class="{ 'unicos_text_xs': $vuetify.breakpoint.xs,
                            'unicos_text_sm': $vuetify.breakpoint.sm,
                            'unicos_text_md': $vuetify.breakpoint.mdAndUp }"
                  >
                  100% Maguey espadín de San Juan Ixtepec creado con el más suculento proceso artesanal.
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-divider vertical class="pink darken-1" v-if="$vuetify.breakpoint.mdAndUp"></v-divider>
        <v-col cols="12" md="4">
          <v-container>
            <v-row>
              <v-col cols="12" class="text-center">
                <div
                  class="colibrije_pink colibrije_mestiza_medium"
                  :class="{ 'unicos_subtitle_xs': $vuetify.breakpoint.xs,
                            'unicos_subtitle_sm': $vuetify.breakpoint.sm,
                            'unicos_subtitle_md': $vuetify.breakpoint.mdAndUp }"
                >
                  Nuestro Interés
                </div>
              </v-col>
            </v-row>
            <br>
            <br v-if="$vuetify.breakpoint.mdAndUp">
            <v-row>
              <v-col cols="12" class="text-center">
                <div
                  class="colibrije_pink colibrije_mestiza_regular"
                  :class="{ 'unicos_text_xs': $vuetify.breakpoint.xs,
                            'unicos_text_sm': $vuetify.breakpoint.sm,
                            'unicos_text_md': $vuetify.breakpoint.mdAndUp }"
                >
                  Nuestro mezcal se produce apoyando a las comunidades oaxaqueñas, fomentando el desarrollo sustentable.
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-divider vertical class=" pink darken-1" v-if="$vuetify.breakpoint.mdAndUp"></v-divider>
        <v-col cols="12" md="4">
          <v-container>
            <v-row>
              <v-col cols="12" class="text-center">
                <div
                  class="colibrije_pink colibrije_mestiza_medium"
                  :class="{ 'unicos_subtitle_xs': $vuetify.breakpoint.xs,
                            'unicos_subtitle_sm': $vuetify.breakpoint.sm,
                            'unicos_subtitle_md': $vuetify.breakpoint.mdAndUp }"
                >
                  Nuestra Motivación
                </div>
              </v-col>
            </v-row>
            <br>
            <br v-if="$vuetify.breakpoint.mdAndUp">
            <v-row>
              <v-col cols="12" class="text-center">
                <div
                  class="colibrije_pink colibrije_mestiza_regular"
                  :class="{ 'unicos_text_xs': $vuetify.breakpoint.xs,
                            'unicos_text_sm': $vuetify.breakpoint.sm,
                            'unicos_text_md': $vuetify.breakpoint.mdAndUp }"
                >
                  Llevar este arte líquido a diferentes partes del mundo y que se sorprendan de la profundidad de su sabor y su origen artesanal.
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <br>
      <br>
      <v-row>
        <v-col>
          <v-img
            :width="150"
            src="@/assets/colibrije1.png"
            class="center_img"
          ></v-img>
        </v-col>
      </v-row>
      <br>
      <br>
    </v-container>
    <!-- </v-parallax> -->

    <v-parallax :height="formasBeberParallaxHeight" src='@/assets/formas_de_beber_d1.jpg'>
      <v-row align="center" justify="space-around">
        <v-col class="text-center" cols="12">
          <v-container>
            <v-row>
              <v-col cols="12" class="text-center">
                <div
                  class="colibrije_blue colibrije_mestiza_medium"
                  :class="{ 'formas_beber_title_xs': $vuetify.breakpoint.xs,
                            'formas_beber_title_sm': $vuetify.breakpoint.sm,
                            'formas_beber_title_md': $vuetify.breakpoint.mdAndUp }"
                  >
                  FORMAS DE BEBER
                </div>
              </v-col>
            </v-row>
            <br>
            <br>
            <br>
            <br>
            <v-row align="center" justify="space-around">
              <v-col cols="10" class="text-center">
                <div
                  class="colibrije_blue colibrije_mestiza_medium"
                  :class="{ 'formas_beber_text_xs': $vuetify.breakpoint.xs,
                            'formas_beber_text_sm': $vuetify.breakpoint.sm,
                            'formas_beber_text_md': $vuetify.breakpoint.mdAndUp }"
                  >
                  Descubre más de 50 formas diferentes de disfrutar COLIBRIJE.
                  <br>
                  <br>
                  ¿Te atreves?
                </div>
              </v-col>
            </v-row>
            <br>
            <br>
            <v-row>
              <v-col>
                <v-img
                  :width="150"
                  src="@/assets/colibrije1.png"
                  class="center_img"
                ></v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-parallax>
    
    <v-container fluid class="ma-0 pa-0">
      <v-row align="center">
        <v-col cols="12" md="6">
          <v-parallax height="800" src='@/assets/recetas_d1.jpg'>
            
            <v-container>
              <v-row align="center" justify="space-around">
                <v-col cols="10" class="text-center">
                  <div class="colibrije_pink my_subtitle colibrije_mestiza_regular">
                    EL <b>MEZCAL</b> CURA. DESPUÉS DE UNOS <b>TRAGUITOS</b> YA NO PIENSO EN <b>TI</b>.
                    <br>
                    <br>
                    <b>COLIBRIJE</b>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          
          </v-parallax>
        </v-col>
        <v-col cols="12" md="6"
          class="text-center colibrije_pink colibrije_mestiza_medium"
          :class="{ 'recetas_xs': $vuetify.breakpoint.xs,
                    'recetas_sm': $vuetify.breakpoint.sm,
                    'recetas_md': $vuetify.breakpoint.mdAndUp }"
          @mouseover="onRecetasHover"
          @mouseleave="onRecetasLeave"
        >
          <div>
            <video
              id="recetasMovie"
              ref="recetasMovie"
              :height="recetasMovieHeight"
              muted
              @ended="recetasMovieEnded"
              @canplay="recetasCanPlay"
            >
              <source src='@/assets/colibrije2.mp4'>
            </video>
          </div>
          <div @click="onRecetas" class="my_mouse_pointer">
            RECETAS
          </div>
        </v-col>
      </v-row>
      <v-divider v-if="$vuetify.breakpoint.smAndDown" class="pink darken-1"></v-divider>
      <br v-if="$vuetify.breakpoint.smAndDown">
      <v-row align="center">
        <v-col cols="12" md="6"
              class="text-center colibrije_blue colibrije_mestiza_medium"
              :class="{ 'destilacion_xs': $vuetify.breakpoint.xs,
                        'destilacion_sm': $vuetify.breakpoint.sm,
                        'destilacion_md': $vuetify.breakpoint.mdAndUp }"
              @mouseover="onDestilacionHover"
              @mouseleave="onDestilacionLeave"
        >
          <div>
            <video
              id="destilacionMovie"
              ref="destilacionMovie"
              :height="destilacionMovieHeight"
              muted
              @ended="destilacionMovieEnded"
              @canplay="destilacionCanPlay"
            >
              <source src='@/assets/colibrije2.mp4'>
            </video>
          </div>
          <div @click="onDestilacion" class="my_mouse_pointer">
            DESTILACIÓN
          </div>   
        </v-col>
        <v-col cols="12" md="6">
          <v-parallax height="800" src='@/assets/destilacion_d1.jpg'>
            <v-container>
              <v-row align="center" justify="space-around">
                <v-col cols="10" class="text-center">
                  <div class="colibrije_blue my_subtitle colibrije_mestiza_regular">
                    PARA UN BESO, <b>MEZCAL</b>, Y PARA <b>ENAMORAR</b>, TODO EL <b>TIEMPO</b> DEL MUNDO TE DEBERÍAS <b>TOMAR</b>.
                    <br>
                    <br>
                    <b>COLIBRIJE</b>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-parallax>
        </v-col>
      </v-row>
      <!-- <br v-if="$vuetify.breakpoint.smAndDown">
      <v-divider v-if="$vuetify.breakpoint.smAndDown" class="blue darken-4"></v-divider>
      <br v-if="$vuetify.breakpoint.smAndDown"> -->
      <v-parallax height="600" src='@/assets/nuestra_historia_d.jpg'>
        <v-row align="start" justify="end">
          <v-col class="text-center" cols="auto">
            <v-container>
              <v-row>
                <v-col cols="12" class="text-center my_mouse_pointer" @click="onHistoria">
                  <div
                    class="colibrije_blue my_title colibrije_mestiza_medium"
                    :class="{ 'historia_title_xs': $vuetify.breakpoint.xs,
                              'historia_title_sm': $vuetify.breakpoint.sm,
                              'historia_title_md': $vuetify.breakpoint.mdAndUp }"
                  >
                    NUESTRA HISTORIA
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-parallax>
    </v-container>
    

    
  </span>
</template>

<script>
import { watch } from 'vue';

export default {
  name: 'Home',

  data() {
    return {
      recetasHover: false,
      recetasHovPlaying: false,
      recetasCanBeginPlay: false,
      recetasMovieIsVisible: false,

      destilacionHover: false,
      destilacionHovPlaying: false,
      destilacionCanBeginPlay: false,
      destilacionMovieIsVisible: false,

      items: [
        {
          text: 'PARA TODO MAL, UN BUEN MEZCAL. PARA TODO BIEN, TAMBIÉN.',
          textColor: 'pink',
          img: require('@/assets/botella1.png'),
          background: ''
        },
        {
          text: 'SI NO HAY REMEDIO, LITRO Y MEDIO.',
          textColor: 'blue',
          img: require('@/assets/botella1.png'),
          background: require('@/assets/carrusel1.jpg'),
        },
        {
          text: 'DAS BIER WIRD MIT MEZCAL BEGLEITET.',
          textColor: 'blue',
          img: require('@/assets/botella1.png'),
          background: require('@/assets/carrusel2.jpg'),
        }
      ],
    }
  },

  methods: {
    onHistoria()
    {
      this.$router.push('/historia').catch(err => {});
    },

    onRecetasHover()
    {
      //console.log("recetasHover");
      //console.log("recetasHovPlaying = ", this.recetasHovPlaying);
      //console.log('recetasCanBeginplay = ', this.recetasCanBeginPlay);

      if (!this.recetasHover)
      {
        this.recetasHover = true;
        if (!this.recetasHovPlaying && this.recetasCanBeginPlay)
        {
          this.recetasHovPlaying = true;
          this.recetasMovieIsVisible = true;
          this.recetasCanBeginPlay = false;
          this.$refs.recetasMovie.play();
          //console.log("recetasMovie.play");

          /*var e = document.getElementById('recetasMovie');
          e.style.opacity = 0;
          var vid = document.getElementById("recetasMovie");
          vid.oncanplaythrough = ()=>{
            setTimeout(()=>{
              var e = document.getElementById("recetasMovie");
              this.fade(e);
            }, 100);
          }*/
        }
      }
    },

    recetasMovieEnded()
    {
      //console.log("Recetas movie ended");
      this.recetasHovPlaying = false;
      this.recetasCanBeginPlay = true;

      if (!this.recetasHover)
      {
        this.drecetasMovieIsVisible = false;
        this.$refs.recetasMovie.load();
      }

      //Si es dispositivo móvil, al acabar la animación se abre la sección de recetas:
      if (this.$vuetify.breakpoint.xs)
      {
        this.$router.push('/historia').catch(err => {});
      }
    },

    onRecetasLeave()
    {
      //console.log("Recetas leave");
      this.recetasHover = false;
      this.recetasCanBeginPlay = true;
      
      if (!this.recetasHovPlaying)
      {
        this.recetasMovieIsVisible = false;
        this.$refs.recetasMovie.load(); //Se resetea el video
      }
    },

    recetasCanPlay()
    {
      this.recetasCanBeginPlay = true;
    },

    onRecetas()
    {
      //Si es dispositivo móvil, se activa la animación y cuando termina ésta se
      //abre la sección de recetas:
      if (this.$vuetify.breakpoint.xs)
      {
        this.onRecetasHover();
      }
      else
      if (this.recetasMovieIsVisible)
      {
        this.$router.push('/historia').catch(err => {});
      }
    },


    onDestilacionHover()
    {
      //console.log("destilacionHover");
      //console.log("destilacionHovPlaying = ", this.destilacionHovPlaying);
      //console.log('destilacionCanBeginplay = ', this.destilacionCanBeginPlay);

      if (!this.destilacionHover)
      {
        this.destilacionHover = true;
        if (!this.destilacionHovPlaying && this.destilacionCanBeginPlay)
        {
          this.destilacionHovPlaying = true;
          this.destilacionMovieIsVisible = true;
          this.destilacionCanBeginPlay = false;
          this.$refs.destilacionMovie.play();
          //console.log("destilacionMovie.play");

          /*var e = document.getElementById('destilacionMovie');
          e.style.opacity = 0;
          var vid = document.getElementById("destilacionMovie");
          vid.oncanplaythrough = ()=>{
            setTimeout(()=>{
              var e = document.getElementById("destilacionMovie");
              this.fade(e);
            }, 100);
          }*/
        }
      }
    },

    fade(element){
      var op = 0;
      var timer = setInterval(function() {
        if (op >= 1)
        {
          clearInterval(timer);
        }
        element.style.opacity = op;
        element.style.filter = 'alpha(opacity=' + op * 100 + ')';
        //console.log("fade, element.style.filter = ", element.style.filter)
        op += op * 0.1 || 0.1;
      },  50);
    },

    destilacionMovieEnded()
    {
      //console.log("Destilacion movie ended");
      this.destilacionHovPlaying = false;
      this.destilacionCanBeginPlay = true;

      if (!this.destilacionHover)
      {
        this.destilacionMovieIsVisible = false;
        this.$refs.destilacionMovie.load();
      }

      //Si es dispositivo móvil, al acabar la animación se abre la sección de destilación:
      if (this.$vuetify.breakpoint.xs)
      {
        this.$router.push('/historia').catch(err => {});
      }
    },

    onDestilacionLeave()
    {
      //console.log("Destilacion leave");
      this.destilacionHover = false;
      this.destilacionCanBeginPlay = true;
      
      if (!this.destilacionHovPlaying)
      {
        this.destilacionMovieIsVisible = false;
        this.$refs.destilacionMovie.load();
      }
    },

    destilacionCanPlay()
    {
      this.destilacionCanBeginPlay = true;
    },

    onDestilacion()
    {
      //Si es dispositivo móvil, se activa la animación y cuando termina ésta se
      //abre la sección de destilación:
      if (this.$vuetify.breakpoint.xs)
      {
        this.onDestilacionHover();
      }
      else
      if (this.destilacionMovieIsVisible)
      {
        this.$router.push('/historia').catch(err => {});
      }
    },
      
  },

  created() {
    // this.$store.dispatch('login', {
    //   user: 'xtortolero@gtsf.com.mx',
    //   password: 'patito'
    // });
    //console.log("items = ", this.items);
    
  },

  // components: {
  //   HelloWorld
  // }

  computed:
  {
    carouselImgWidth()
    {
      var width = '100%';
      if (this.$vuetify.breakpoint.xs)
      {
        width = '50%';
      }
      else
      if (this.$vuetify.breakpoint.sm)
      {
        width = '40%';
      }
      else
      if (this.$vuetify.breakpoint.md)
      {
        width = '70%';
      }
      else
      if (this.$vuetify.breakpoint.lg)
      {
        width = '75%';
      }
      else
      if (this.$vuetify.breakpoint.xl)
      {
        width = '80%';
      }
      return width;
  },

  carouselParallaxHeight()
  {
    var height = '750';
    if (this.$vuetify.breakpoint.xs)
    {
      height = '1000';
    }
    else if
    (this.$vuetify.breakpoint.sm)
    {
      height = '800';
    }
    else
    if (this.$vuetify.breakpoint.md)
    {
      height = '750';
    }
    else
    if (this.$vuetify.breakpoint.lg)
    {
      height = '750';
    }
    else
    if (this.$vuetify.breakpoint.xl)
    {
      height = '750';
    }
    return height;
  },

  formasBeberParallaxHeight()
  {
    var height = '750';
    if (this.$vuetify.breakpoint.xs)
    {
      height = '1000';
      }
      else 
      if (this.$vuetify.breakpoint.sm)
      {
        height = '800';
      }
      else
      if (this.$vuetify.breakpoint.md)
      {
        height = '760';
      }
      else
      if (this.$vuetify.breakpoint.lg)
      {
        height = '820';
      }
      else
      if (this.$vuetify.breakpoint.xl)
      {
        height = '750';
      }
      return height;
    },

    recetasMovieHeight()
    {
      var height = '1';
      if (!this.recetasMovieIsVisible)
      {
        //height = '1';
        height = '250';
      }
      else
      {
        height = '250';
      }
      return height;
    },

    destilacionMovieHeight()
    {
      var height = '1';
      if (!this.destilacionMovieIsVisible)
      {
        //height = '1';
        height = '250';
      }
      else
      {
        height = '250';
      }
      return height;
    }
  },
}
</script>

<style scoped>

.colibrije_mestiza_black {
    font-family: mestiza_black;
}

@font-face {
    font-family: mestiza_black;
    src: url('../css/Mestiza-Black.otf');
}

.colibrije_mestiza_medium {
    font-family: mestiza_medium;
}

@font-face {
    font-family: mestiza_medium;
    src: url('../css/Mestiza-Black.otf');
}

.colibrije_mestiza_regular {
    font-family: mestiza_regular;
}

@font-face {
    font-family: mestiza_regular;
    src: url('../css/Mestiza-Regular.otf');
}

.center_img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.colibrije_blue {
  color: rgb(8,31,77);
}

.colibrije_pink {
  color: rgb(230,16,101) !important;
}

.my_carousel_xs {
  font-size: 2.1em;
}

.my_carousel_sm {
  font-size: 2.5em;
}

.my_carousel_md {
  font-size: 3.6em;
}

.unicos_title_xs {
  font-size: 2.1em;
}

.unicos_title_sm {
  font-size: 2.5em;
}

.unicos_title_md {
  font-size: 3.6em;
}

.unicos_subtitle_xs {
  font-size: 1.8em;
}

.unicos_subtitle_sm {
  font-size: 2em;
}

.unicos_subtitle_md {
  font-size: 2.5em;
}

.unicos_text_xs {
  font-size: 1.4em;
}

.unicos_text_sm {
  font-size: 1.6em;
}

.unicos_text_md {
  font-size: 1.9em;
}

.formas_beber_title_xs {
  font-size: 2.5em;
}

.formas_beber_title_sm {
  font-size: 3.2em;
}

.formas_beber_title_md {
  font-size: 3.9em;
}

.formas_beber_text_xs {
  font-size: 1.8em;
}

.formas_beber_text_sm {
  font-size: 2em;
}

.formas_beber_text_md {
  font-size: 2.5em;
}

.my_title {
  font-size: 3.5em;
}

.historia_title_xs {
  font-size: 2.5em;
}

.historia_title_sm {
  font-size: 3.2em;
}

.historia_title_md {
  font-size: 3.9em;
}

.my_zoom_title {
  font-size: 3.4em;
}

.my_subtitle {
  font-size: 2.5em;
}

.my_section_title {
  font-size: 1.4em;
}

.my_section_text {
  font-size: 1.9em;
}

.v-parallax__image {
  transform: none !important;
  width: 100% !important;
  background-position: 50% 210px !important;
  height: 600px;
  background-size: 100%;
}

.my_mouse_pointer {
  cursor: pointer;
}

.recetas_md {
  font-size: 3.3em;
  margin-bottom: 20%;
  transition: all 1s;
}

.recetas_md:hover {
  font-size: 4.3em;
  margin-bottom: 7%;
}

.recetas_sm {
  font-size: 3em;
  margin-bottom: 20%;
  transition: all 1s;
}

.recetas_sm:hover {
  font-size: 4em;
  margin-bottom: 7%;
}

.recetas_xs {
  font-size: 2.8em;
  margin-bottom: 40%;
  transition: all 1s;
}

.recetas_xs:hover {
  font-size: 3.4em;
  margin-bottom: 10%;
}

.destilacion_md {
  font-size: 3.3em;
  margin-bottom: 20%;
  transition: all 1s;
}

.destilacion_md:hover {
  font-size: 4.3em;
  margin-bottom: 7%;
}

.destilacion_sm {
  font-size: 3em;
  margin-bottom: 20%;
  transition: all 1s;
}

.destilacion_sm:hover {
  font-size: 4em;
  margin-bottom: 7%;
}

.destilacion_xs {
  font-size: 2.8em;
  margin-bottom: 40%;
  transition: all 1s;
}

.destilacion_xs:hover {
  font-size: 3.4em;
  margin-bottom: 10%;
}


</style>
