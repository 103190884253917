import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        version: '1.0.0',
        navApptitle: 'Colibrije',
        navMainLogo: require('@/assets/colibrije_color_horiz.png'),
        bWaitCursor: false,
    },

    mutations: {},

    actions: {
        login({ commit, dispatch, state }, authData) {
            state.bWaitCursor = true;

            axios
                .post('/usuarios/login/', null, {
                    params: {
                        user: authData.user,
                        password: authData.password,
                    },
                })
                .then((res) => {
                    state.bWaitCursor = false;
                    console.log('res = ', res);
                })
                .catch((error) => {
                    console.log('Error: ', error);
                    state.bWaitCursor;
                });
        },
    },

    getters: {
        version: (state) => {
            return state.version;
        },
        navAppTitle: (state) => {
            return state.navAppTitle;
        },
        navMainLogo: (state) => {
            return state.navMainLogo;
        },
        waitCursor: (state) => {
            return state.bWaitCursor;
        },
    },
    modules: {},
});
