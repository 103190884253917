var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[_c('v-parallax',{attrs:{"height":_vm.carouselParallaxHeight,"src":require("@/assets/garigoleado_cafe1.jpg")}},[_c('v-carousel',{attrs:{"height":"auto","hide-delimiter-background":"","show-arrows-on-hover":"","cycle":""}},_vm._l((_vm.items),function(item,i){return _c('v-carousel-item',{key:i},[_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"colibrije_mestiza_medium",class:{'my_carousel_xs': _vm.$vuetify.breakpoint.xs,
                       'my_carousel_sm': _vm.$vuetify.breakpoint.sm,
                       'my_carousel_md': _vm.$vuetify.breakpoint.mdAndUp,
                       'colibrije_pink': item.textColor === 'pink',
                       'colibrije_blue': item.textColor === 'blue'}},[_vm._v(" "+_vm._s(item.text)+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"3","align-self":"center"}},[(item.img !== '')?_c('v-img',{staticClass:"center_img",attrs:{"src":item.img,"width":_vm.carouselImgWidth}}):_vm._e()],1)],1)],1)],1)}),1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"colibrije_pink colibrije_mestiza_medium",class:{ 'unicos_title_xs': _vm.$vuetify.breakpoint.xs,
                    'unicos_title_sm': _vm.$vuetify.breakpoint.sm,
                    'unicos_title_md': _vm.$vuetify.breakpoint.mdAndUp }},[_c('br'),_vm._v(" QUÉ NOS HACE ÚNICOS "),_c('br'),_c('br')])])],1),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-divider',{staticClass:"pink darken-1"}):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('br'):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","align":"center"}},[_c('div',{staticClass:"colibrije_pink colibrije_mestiza_medium",class:{ 'unicos_subtitle_xs': _vm.$vuetify.breakpoint.xs,
                          'unicos_subtitle_sm': _vm.$vuetify.breakpoint.sm,
                          'unicos_subtitle_md': _vm.$vuetify.breakpoint.mdAndUp }},[_vm._v(" Nuestro Agave ")])])],1),_c('br'),(_vm.$vuetify.breakpoint.mdAndUp)?_c('br'):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"colibrije_pink colibrije_mestiza_regular",class:{ 'unicos_text_xs': _vm.$vuetify.breakpoint.xs,
                          'unicos_text_sm': _vm.$vuetify.breakpoint.sm,
                          'unicos_text_md': _vm.$vuetify.breakpoint.mdAndUp }},[_vm._v(" 100% Maguey espadín de San Juan Ixtepec creado con el más suculento proceso artesanal. ")])])],1)],1)],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-divider',{staticClass:"pink darken-1",attrs:{"vertical":""}}):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"colibrije_pink colibrije_mestiza_medium",class:{ 'unicos_subtitle_xs': _vm.$vuetify.breakpoint.xs,
                          'unicos_subtitle_sm': _vm.$vuetify.breakpoint.sm,
                          'unicos_subtitle_md': _vm.$vuetify.breakpoint.mdAndUp }},[_vm._v(" Nuestro Interés ")])])],1),_c('br'),(_vm.$vuetify.breakpoint.mdAndUp)?_c('br'):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"colibrije_pink colibrije_mestiza_regular",class:{ 'unicos_text_xs': _vm.$vuetify.breakpoint.xs,
                          'unicos_text_sm': _vm.$vuetify.breakpoint.sm,
                          'unicos_text_md': _vm.$vuetify.breakpoint.mdAndUp }},[_vm._v(" Nuestro mezcal se produce apoyando a las comunidades oaxaqueñas, fomentando el desarrollo sustentable. ")])])],1)],1)],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-divider',{staticClass:"pink darken-1",attrs:{"vertical":""}}):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"colibrije_pink colibrije_mestiza_medium",class:{ 'unicos_subtitle_xs': _vm.$vuetify.breakpoint.xs,
                          'unicos_subtitle_sm': _vm.$vuetify.breakpoint.sm,
                          'unicos_subtitle_md': _vm.$vuetify.breakpoint.mdAndUp }},[_vm._v(" Nuestra Motivación ")])])],1),_c('br'),(_vm.$vuetify.breakpoint.mdAndUp)?_c('br'):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"colibrije_pink colibrije_mestiza_regular",class:{ 'unicos_text_xs': _vm.$vuetify.breakpoint.xs,
                          'unicos_text_sm': _vm.$vuetify.breakpoint.sm,
                          'unicos_text_md': _vm.$vuetify.breakpoint.mdAndUp }},[_vm._v(" Llevar este arte líquido a diferentes partes del mundo y que se sorprendan de la profundidad de su sabor y su origen artesanal. ")])])],1)],1)],1)],1),_c('br'),_c('br'),_c('v-row',[_c('v-col',[_c('v-img',{staticClass:"center_img",attrs:{"width":150,"src":require("@/assets/colibrije1.png")}})],1)],1),_c('br'),_c('br')],1),_c('v-parallax',{attrs:{"height":_vm.formasBeberParallaxHeight,"src":require("@/assets/formas_de_beber_d1.jpg")}},[_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"colibrije_blue colibrije_mestiza_medium",class:{ 'formas_beber_title_xs': _vm.$vuetify.breakpoint.xs,
                          'formas_beber_title_sm': _vm.$vuetify.breakpoint.sm,
                          'formas_beber_title_md': _vm.$vuetify.breakpoint.mdAndUp }},[_vm._v(" FORMAS DE BEBER ")])])],1),_c('br'),_c('br'),_c('br'),_c('br'),_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"10"}},[_c('div',{staticClass:"colibrije_blue colibrije_mestiza_medium",class:{ 'formas_beber_text_xs': _vm.$vuetify.breakpoint.xs,
                          'formas_beber_text_sm': _vm.$vuetify.breakpoint.sm,
                          'formas_beber_text_md': _vm.$vuetify.breakpoint.mdAndUp }},[_vm._v(" Descubre más de 50 formas diferentes de disfrutar COLIBRIJE. "),_c('br'),_c('br'),_vm._v(" ¿Te atreves? ")])])],1),_c('br'),_c('br'),_c('v-row',[_c('v-col',[_c('v-img',{staticClass:"center_img",attrs:{"width":150,"src":require("@/assets/colibrije1.png")}})],1)],1)],1)],1)],1)],1),_c('v-container',{staticClass:"ma-0 pa-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-parallax',{attrs:{"height":"800","src":require("@/assets/recetas_d1.jpg")}},[_c('v-container',[_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"10"}},[_c('div',{staticClass:"colibrije_pink my_subtitle colibrije_mestiza_regular"},[_vm._v(" EL "),_c('b',[_vm._v("MEZCAL")]),_vm._v(" CURA. DESPUÉS DE UNOS "),_c('b',[_vm._v("TRAGUITOS")]),_vm._v(" YA NO PIENSO EN "),_c('b',[_vm._v("TI")]),_vm._v(". "),_c('br'),_c('br'),_c('b',[_vm._v("COLIBRIJE")])])])],1)],1)],1)],1),_c('v-col',{staticClass:"text-center colibrije_pink colibrije_mestiza_medium",class:{ 'recetas_xs': _vm.$vuetify.breakpoint.xs,
                  'recetas_sm': _vm.$vuetify.breakpoint.sm,
                  'recetas_md': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"cols":"12","md":"6"},on:{"mouseover":_vm.onRecetasHover,"mouseleave":_vm.onRecetasLeave}},[_c('div',[_c('video',{ref:"recetasMovie",attrs:{"id":"recetasMovie","height":_vm.recetasMovieHeight,"muted":""},domProps:{"muted":true},on:{"ended":_vm.recetasMovieEnded,"canplay":_vm.recetasCanPlay}},[_c('source',{attrs:{"src":require("@/assets/colibrije2.mp4")}})])]),_c('div',{staticClass:"my_mouse_pointer",on:{"click":_vm.onRecetas}},[_vm._v(" RECETAS ")])])],1),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-divider',{staticClass:"pink darken-1"}):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('br'):_vm._e(),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"text-center colibrije_blue colibrije_mestiza_medium",class:{ 'destilacion_xs': _vm.$vuetify.breakpoint.xs,
                      'destilacion_sm': _vm.$vuetify.breakpoint.sm,
                      'destilacion_md': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"cols":"12","md":"6"},on:{"mouseover":_vm.onDestilacionHover,"mouseleave":_vm.onDestilacionLeave}},[_c('div',[_c('video',{ref:"destilacionMovie",attrs:{"id":"destilacionMovie","height":_vm.destilacionMovieHeight,"muted":""},domProps:{"muted":true},on:{"ended":_vm.destilacionMovieEnded,"canplay":_vm.destilacionCanPlay}},[_c('source',{attrs:{"src":require("@/assets/colibrije2.mp4")}})])]),_c('div',{staticClass:"my_mouse_pointer",on:{"click":_vm.onDestilacion}},[_vm._v(" DESTILACIÓN ")])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-parallax',{attrs:{"height":"800","src":require("@/assets/destilacion_d1.jpg")}},[_c('v-container',[_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"10"}},[_c('div',{staticClass:"colibrije_blue my_subtitle colibrije_mestiza_regular"},[_vm._v(" PARA UN BESO, "),_c('b',[_vm._v("MEZCAL")]),_vm._v(", Y PARA "),_c('b',[_vm._v("ENAMORAR")]),_vm._v(", TODO EL "),_c('b',[_vm._v("TIEMPO")]),_vm._v(" DEL MUNDO TE DEBERÍAS "),_c('b',[_vm._v("TOMAR")]),_vm._v(". "),_c('br'),_c('br'),_c('b',[_vm._v("COLIBRIJE")])])])],1)],1)],1)],1)],1),_c('v-parallax',{attrs:{"height":"600","src":require("@/assets/nuestra_historia_d.jpg")}},[_c('v-row',{attrs:{"align":"start","justify":"end"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"auto"}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"text-center my_mouse_pointer",attrs:{"cols":"12"},on:{"click":_vm.onHistoria}},[_c('div',{staticClass:"colibrije_blue my_title colibrije_mestiza_medium",class:{ 'historia_title_xs': _vm.$vuetify.breakpoint.xs,
                            'historia_title_sm': _vm.$vuetify.breakpoint.sm,
                            'historia_title_md': _vm.$vuetify.breakpoint.mdAndUp }},[_vm._v(" NUESTRA HISTORIA ")])])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }