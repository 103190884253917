import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store/store';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import i18n from './i18n';

//axios.defaults.baseURL = 'http://arqibero.gtsf.com.mx';

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
