<template>
  <v-app>
    <app-navigation></app-navigation>
    <!-- <v-content transition="slide-x-transition"> -->
    <v-main transition="slide-x-transition">
      <router-view></router-view>
    </v-main>
    <app-footer></app-footer>
  </v-app>
</template>

<script>
import AppNavigation from '@/components/AppNavigation';
import AppFooter from '@/components/AppFooter';

export default {
  name: 'App',

  data: () => ({}),
  components: {
    AppNavigation, AppFooter
  }
};
</script>
